import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import clsx from 'clsx';
import OptimizedImage from '../../utils/OptimizedImage';
import { FundingBarStyles } from '../../styles/Components/FundingBar/FundingBarStyles';

const FundingBar = ({ component }) => {
  const { heading, fundingSources, theme } = component;
  const BACKGROUND_MAPPING = {
    Light: 'bg-light',
    Blue: 'bg-blue',
    Charcoal: 'bg-charcoal',
    Midnight: 'bg-midnight',
  };
  const determineBackgroundClass = () =>
    component ? BACKGROUND_MAPPING[theme] : '';
  return (
    <FundingBarStyles className={clsx(determineBackgroundClass())}>
      <Container>
        <Row className="justify-content-between align-items-center">
          <Col lg={12}>
            <p className="heading">{heading}</p>
          </Col>
          {fundingSources &&
            fundingSources.map((item, idx) => (
              <Col
                lg="auto"
                md="auto"
                key={`source${idx}`}
                className="text-center"
              >
                {item?.monotoneLogo?.gatsbyImageData ? (
                  <OptimizedImage
                    image={item?.monotoneLogo?.gatsbyImageData}
                    alt={item?.monotoneLogo?.alt}
                    title={item?.monotoneLogo?.title}
                  />
                ) : (
                  <OptimizedImage
                    src={item?.monotoneLogo?.url}
                    alt={item?.monotoneLogo?.alt}
                    title={item?.monotoneLogo?.title}
                  />
                )}
              </Col>
            ))}
        </Row>
      </Container>
    </FundingBarStyles>
  );
};

export default FundingBar;
